export const strings = {
    // General
    of: 'av',
    ok: 'OK',
    yes: 'Ja',
    got_it: 'Den er grei',
    see_more: 'Se mer',
    see_album: 'Se albumet',
    read_more: 'Les mer',
    skip: 'Hopp over',
    no: 'Nei',
    done: 'Fullfør',
    reactivate: 'Reaktiver',
    subscribe: 'Abonner',
    unsubscribe: 'Forlat',
    delete_: 'Slett',
    delete_all: 'Slett alle',
    download: 'Last ned',
    export: 'Eksporter',
    edit: 'Rediger',
    copy: 'Kopier',
    update: 'Oppdater',
    cancel: 'Avbryt',
    share: 'Del',
    undo: 'Angre',
    back: 'Tilbake',
    go_back: 'Tilbake',
    select_all: 'Velg alle',
    restore: 'Gjenopprett',
    restore_all: 'Gjenopprett alle',
    filename: 'filnavn',
    file_type: 'filtype',
    deleted: 'slettet',
    unknown: 'ukjent',
    retry: 'Prøv igjen',
    create_account: 'Opprett konto',
    sign_in: 'Logg på',
    only_accessible_to_logged_in_users:
        'Denne delen av tjenesten krever pålogging',
    loading: 'Laster',
    login_again_for_security:
        'Av sikkerhetsmessige årsaker, vennligst logg inn for å se dine personverninnstillinger',
    logged_out_prompt:
        'Ops, du ble logget ut. Vennligst logg inn for å fortsette',
    capture_save_see_share: 'Lagre, se og del alle dine bilder og videoer.',
    feature_only_available_in_app:
        'Denne funksjonen er foreløpig kun tilgjengelig i Min Sky-appen.',
    open_capture: 'Åpne Min Sky',
    open_in_app: 'Åpne i app',
    storage: 'Lagring',
    active: 'Aktiv',
    upcoming: 'Oppkommende',
    save: 'Lagre',
    // for buttons
    details: 'Detaljer',
    accept: 'Godta',
    deny: 'Avslå',
    num_of_total__format: '%num% av %total%',
    autoplay: 'autoavspilling',
    something_went_wrong: 'Ops! Noe gikk galt.',
    cookies: 'Informasjonskapsler',
    invalid_field: 'Verdien er ikke gyldig',
    mandatory_field: 'Feltet må fylles ut',
    movies: 'Video',
    recents: 'Nylige',
    here: 'her',
    screenshots: 'Skjermbilder',
    item: 'element',
    items: 'element',
    confirm: 'Bekreft',
    full_name: 'Fullt Navn',
    address: 'Adresse',
    telephone_number: 'Telefonnummer',

    // media types
    photos: 'Bilder',
    image: 'Bilde',
    livephoto: 'Live photo',
    movie: 'Video',
    file: 'fil',
    files: 'filer',

    // Time
    january: 'Januar',
    february: 'Februar',
    march: 'Mars',
    april: 'April',
    may: 'Mai',
    june: 'Juni',
    july: 'Juli',
    august: 'August',
    september: 'September',
    october: 'Oktober',
    november: 'November',
    december: 'Desember',
    day: 'Dag',
    month: 'Måned',
    year: 'År',
    monthly: 'månedlig',
    yearly: 'årlig',
    jan: 'Jan',
    feb: 'Feb',
    mar: 'Mar',
    apr: 'Apr',
    jun: 'Jun',
    jul: 'Jul',
    aug: 'Aug',
    sep: 'Sept',
    oct: 'Okt',
    nov: 'Nov',
    dec: 'Des',
    num_days__format: '%d dager',
    one_day: '1 dag',
    // User Action Prompts & Alerts
    delete_files_prompt__format: `%d filer blir flyttet til Slettede elementer. Filene blir slettet permanent etter 90 dager.`,
    delete_single_file_prompt: `Filen blir flyttet til Slettede elementer. Filen blir slettet permanent etter 90 dager.`,
    delete_file_failed_message__format:
        'En feil oppstod under forsøk på å slette "%s". Prøv igjen.',
    // --- album prompts ---
    delete_album_file_prompt_text:
        'Filen blir fjernet fra albumet. Kommentarer og reaksjoner på filen blir også slettet.',
    delete_last_file_prompt_text:
        'Filen blir fjernet fra albumet. Fjerning av siste filen i et album vil også slette albumet.',
    unsubscribe_album_prompt_text:
        'Du vil ikke lenger se dette albumet i albumlisten eller motta oppdateringer om albumet.',
    delete_album_prompt_text:
        'Albumet blir slettet og fjernet fra albumlisten.',
    delete_comment_prompt_text: 'Kommentaren vil bli slettet.',
    copy_files_to_timeline: 'Filene i albumet vil bli kopiert til dine bilder.',
    // --- settings page prompts ---
    remove_device_prompt__format:
        'Du vil bli logget ut av %s på denne enheten.',
    cancel_plan: 'Stopp abonnement',
    keep_plan: 'Behold abonnement',
    // remove once getting charge amount is possible
    upgrade_storage_plan_prompt__format:
        'Abonnementet vil bli oppgradert til %plan_price%/%period% (%size%). Du vil bli belastet %amount% og faktureringssyklusen starter umiddelbart.',
    cancel_subscription_alert_title: 'Vil du si opp abonnementet ditt?',
    cancel_subscription_alert_description_html__format:
        'Lagringsplassen din vil være tilgjengelig til %date%.<br/>Betalingsinformasjonen din vil bli slettet.',
    cancel_subscription_alert_confirm: 'Stopp abonnement',
    cancel_subscription_alert_dismiss: 'Senere',
    cancel_upcoming_subscription_alert_title:
        'Vil du kansellere kommende abonnement?',
    cancel_upcoming_subscription_alert_description:
        'Ved å kansellere det kommende abonnementet vil du gjenoppta ditt nåværende abonnement.',
    cancel_upcoming_subscription_alert_confirm: 'Bekreft',
    cancel_upcoming_subscription_alert_dismiss: 'Senere',
    change_subscription_alert_title: 'Endre abonnement?',
    change_subscription_alert_description_html__format:
        'Du har for øyeblikket et aktivt %current_storage% abonnement. Vil du endre til <span class="newPlanInfo">%new_storage% for %new_price%</span>?',
    change_subscription_alert_confirm: 'Bekreft',
    change_subscription_alert_dismiss: 'Senere',
    subscriptions: 'Abonnement',
    purchase_plan_description_html_format:
        'Du betaler nå <strong>%plan_price%</strong>. Abonnementet vil bli fornyet %period%. Du kan kansellere når som helst.',
    change_plan_description_html_format:
        'Du betaler <strong>%plan_price%</strong> den %plan_start_at%. Abonnementet vil bli fornyet %period%. Du kan kansellere når som helst.',
    // --- terms of service prompts ---
    accept_tos__header: 'Godta Tjenestevilkår',
    accept_tos__we_have_made_changes__format:
        'Vi har gjort endringer i våre %link_start%Tjenestevilkår%link_end%.',
    accept_tos__please_read_and_accept:
        'Ved å bruke tjenesten godtar du %terms_of_service%. Vennligst les dem nøye.',
    decline_tos: 'Avvis og logg ut',
    // --- trash and document prompts ---
    delete_single_document_file: 'Filen blir slettet permanent.',
    delete_document_files__format: '%d filer blir slettet permanent.',
    restore_single_file_prompt: 'Filen blir gjenopprettet til dine bilder.',
    restore_files_prompt__format:
        '%d filer blir gjenopprettet til dine bilder.',
    permanently_delete_single_file_prompt:
        'Filen blir slettet permanent. Dette kan ikke angres.',
    permanently_delete_files_prompt__format:
        '%d filer blir slettet permanent. Dette kan ikke angres.',
    // Navigation
    timeline: 'Bilder',
    albums: 'Album',
    add_files: 'Legg til filer',
    documents: 'Dokumenter',
    settings: 'Innstillinger',
    telenor_ID: 'Telenor ID',
    account: 'Konto',
    help: 'Hjelp',
    help_support: 'Hjelp og støtte',
    deleted_items: 'Slettede elementer',
    login: 'Logg inn',
    log_out: 'Logg ut',
    close: 'Lukk',
    timeline_desc: 'Alle bilder og videoer, organisert etter dato.',
    albums_desc: 'Legg til bilder i et album og del med vennene dine!',
    search_albums: 'Søk etter album',
    no_album_results:
        'Fant ingen resultater for "%link_start%%search_string%%link_end%". Prøv å søk etter et annet album.',
    // Drag and drop
    drag_drop: 'Dra & slipp',
    drag_drop_files__format: 'Dra & slipp filene %split%eller',
    drop_your_files__format:
        'Slipp filene for å laste dem opp til %product_name% umiddelbart!',
    dropZone_text__timeline:
        'bilder for å laste dem opp, eller en mappe for å også lage album',
    dropZone_text__albums: 'bilder eller mapper for å lage nye album',
    dropZone_text__singleAlbum:
        'bilder hvor som helst for å laste dem opp til %album_name%',
    // click_here_to_select: 'or click here to select from you computer',

    // UploaderBox
    file_s: 'fil(er)',
    uploaded: 'lastet opp',
    did_not_upload: 'ble ikke lastet opp',
    upload_folder_reject: 'Kan ikke laste opp mapper',
    upload_folder_safari_reject: 'Safari støtter ikke opplasting av mapper',
    unsupported_file_reject: 'Filen støttes ikke',
    was_not_uploaded: 'Filen ble ikke lastet opp',
    too_large_upload: 'Filen er for stor til å laste opp (Maksimum 200 GB)',
    see_which: 'Se hvilke',
    view_the_files: 'Se filene',
    // show_files: 'Show files',
    // show_photos: 'Show photos',
    offline: 'Ingen internettforbindelse',
    upload: 'Last opp',
    uploading: 'Laster opp',
    // pause: 'Pause',
    // resume: 'Resume',
    retrying_in: 'Prøver igjen om',
    sec: 'sek',
    retrying_now: 'Prøver igen nå...',
    try_again: 'Prøv igjen',
    stop: 'Stopp',
    processing: 'Behandler',
    processing_with_dots: 'Behandler...',
    complete: 'Ferdig',
    cancelled: 'Avbrutt',
    // unknown_format: 'Unknown format',
    preparing: 'Forbereder...',
    waiting_for_internet_connection: 'Venter på internettforbindelse...',
    out_of_storage: 'Tomt for lagringsplass',
    free_up_space: 'Slett bilder eller videoer for å fortsette opplastingen',
    cancelling_upload_are_you_sure: 'Sikker på at du vil stoppe opplastingen?',
    processing_video: 'Prosesserer video',
    // upload options modal
    select_from_device: 'Last opp bilder fra enheten',
    add_from_capture__format: 'Legg til fra %product_name%',
    // Popup
    reached_given_storage_ratio: 'Lite lagringsplass',
    reached_given_storage_ratio_subText:
        'Du har brukt %used_storage%GB ut av %max_storage%GB. Frigjør plass ved å slette bilder eller videoer, eller kjøp mer lagringsplass i Innstillinger.',
    not_enough_storage_for_add: 'Manglende lagringsplass',
    not_enough_storage_for_add_subText:
        'Du har ikke nok lagringsplass for alle filene du prøver å legge til. Frigjør plass ved å slette bilder eller videoer, eller kjøp mer lagringsplass i Innstillinger.',
    not_enough_storage_for_restore: 'Manglende lagringsplass',
    not_enough_storage_for_restore_subText:
        'Du har ikke nok lagringsplass for alle filene du prøver å gjenopprette. Frigjør plass ved å slette bilder eller videoer, eller kjøp mer lagringsplass i Innstillinger.',
    // Popover
    popover__profile_picture_heading: 'Oppdater profilbildet ditt?',
    popover__profile_picture_body:
        'Du kan endre profilbildet ditt i Innstillinger, så alle vet at du er deg!',
    // T I M E L I N E
    // subscription required
    timeline_subscription_required_header:
        'Du må ha et abonnement på Min Sky for å laste opp bilder.',
    timeline_subscription_required_sub:
        'Se våre abonnement og start din gratis prøveperiode',
    timeline_subscription_required_cta: 'Se abonnement',
    // emtpy state
    timeline_is_empty: 'Du har ingen bilder sikkerhetskopiert her.',
    timeline_filtered_photos_empty: 'Du har ingen bilder.',
    timeline_filtered_recents_empty: 'Du har ingen nylige.',
    start_uploading_photos_now: 'Hvorfor ikke laste opp noen bilder nå?',
    start_uploading_now: 'Hvorfor ikke begynne å laste opp noen nå?',
    drag_drop_photos_to_upload:
        'Dra og slipp bildene hit, eller trykk på knappen under.',
    download_app_to_upload_directly_from_device:
        'Installer Min Sky-appen på din mobil eller nettbrett for å laste opp bilder og videoer direkte fra enhetens kamera og bildegalleri',
    timeline_empty_screenshot_filter: 'Du har ingen skjermbilder.',
    // TODO: verify text
    timeline_filtered_videos_empty: 'Du har ingen videoer.',
    drag_drop_files_to_upload:
        'Du kan dra og slippe filene her, eller trykke på knappen under.',
    add_videos: 'Legg til video',
    // select from timeline empty
    upload_photos_add_album: 'Last opp bilder for å legge dem til i albumet.',
    // timeline page
    selected__format: '%d valgt',
    none_selected: 'Ingen elementer valgt',
    select_files: 'Velg filer',
    file_count_exceeds_download_limit:
        'Du kan ikke laste ned mer enn 500 elementer av gangen. Vennligst velg færre elementer.',
    size_exceeds_download_limit:
        'Du kan ikke laste ned mer enn 3.5 GB om gangen. Vennligst velg færre elementer.',
    fetching_file_selection_info: 'Henter informasjon om filer for seleksjon',
    shift_select_hint_header: 'Ønsker du å raskt velge flere bilder samtidig?',
    shift_select_hint_body:
        'Hold shift (⇧) og klikk for å velge flere elementer samtidig.',
    fastscroller_hint_body: 'Gå rett til en måned ved å klikke på den',
    fastscroller_select_hint_body: 'Velg en hel måned ved å klikke her',
    filter_by_videos: 'Video',
    filter_by_images: 'Bilder',
    filter_by_recents: 'Nylig lagt til',
    filtered_by_videos: 'Filtrert etter videoer',
    filtered_by_images: 'Filtrert etter bilder',
    filtered_by_recents: 'Filtrert etter nylige',
    show_all: 'Vis alle',
    download_heic: 'Last ned HEIC',
    download_heic_desc_single: 'Originalfil. Kompatibel med iOS og macOS',
    download_heic_desc_multi:
        'filer som lastet opp. Kompatibel med iOS og macOS',
    download_jpeg: 'Last ned JPEG',
    download_jpeg_desc: 'Kompatibel med de fleste enheter. Anbefalt',
    download_original: 'Last ned original',
    download_original_desc: 'filer som lastet opp',
    download_converted: 'Konverter og last ned',
    download_converted_desc: 'til et format kompatibelt med de fleste enheter',
    show: 'Vis',
    added_today: 'Lagt til i dag',
    added_days__format: 'Lagt til siste %d dager',
    // paginated download
    file__format: '%d fil',
    files__format: '%d filer',
    about_to_download_files__format:
        'Du er i ferd med å laste ned %file_count_size%',
    about_to_download_paginated_description:
        'Nedlasting av mange filer på én gang kan ta en del tid. Sørg for at nettverksforbindelsen din er stabil og at enheten din er tilkoblet en strømkilde.',
    download_all_at_once: 'Last ned alt på en gang',
    download_your_photos_and_videos: 'Last ned bildene og videoene dine',
    download_in_smaller_parts: 'Last ned i mindre deler',
    download_page__format: 'Last ned del %part% (%file_count%)',
    download_paginated_parts_below: 'Vennligst last ned hver av delene under.',
    download_paginated__format:
        'Ditt utvalg av %file_count% %file_count_size% er delt opp i mindre deler for best mulig opplevelse. Klikk på hver zip-fil nedenfor for å laste den ned.',
    download_pagination_convenience:
        'For enkelhets skyld kan du minimere dette vinduet og fortsette å bruke %product_name% mens filene lastes ned.',
    download_files: 'Last ned %file_count% (%file_count_size%)',
    close_window: 'Lukk vindu',
    // close to quota - text ribbon
    close_to_quota__format: '%used_storage% GB brukt av %max_storage% GB',
    // A L B U M S
    album_name: 'Albumnavn',
    album: 'album',
    unnamed_album_placeholder: 'Album uten navn',
    album_settings: 'Innstillinger for album',
    add_to_albums: 'Legg til i mine album',
    subscribe_to_album: 'Abonner på album',
    item__format: '%d element',
    items__format: '%d elementer',
    participant__format: '%d deltaker',
    participants__format: '%d deltakere',
    participants: 'Deltakere',
    owner: 'Eier',
    you_are_participating: 'Du deltar',
    by: 'av',
    create_album: 'Lag album',
    auto_created_album_title: 'Album uten navn',
    new_album: 'Nytt album',
    filter_by: 'Filtrer etter',
    sort_by: 'Sorter etter',
    filter_albums: 'Filtrer album',
    filtered_by_private: 'Filtrert etter privat',
    filtered_by_shared: 'Filtrert etter delt',
    sort_recent_activity: 'Nylig aktivitet',
    sort_newest_created: 'Nylig opprettet',
    sort_oldest_created: 'Eldst opprettet',
    sort_name: 'Albumnavn',
    all_albums: 'Alle album',
    shared_albums: 'Delte album',
    private_albums: 'Private album',
    download_album: 'Last ned album',
    add_photos: 'Legg til bilder',
    add_to_album: 'Legg til i album',
    you_and_others_love_message_format: 'Du og %d andre liker dette',
    you_and_one_love_message: 'Du og 1 liker dette',
    you_love_message: 'Du liker dette',
    one_person_love_message: '1 liker dette',
    people_love_message_format: '%d liker dette',
    leave_album: 'Forlat album',
    delete_album: 'Slett album',
    delete_file: 'Slett fil',
    download_file: 'Last ned fil',
    download_files_failed_message:
        'En feil oppsto ved forsøk på nedlasting. Vennligst prøv igjen senere.',
    set_as_cover_photo: 'Sett som forsidebilde',
    add_file_to_timeline: `Legg til i dine bilder`,
    add_album_to_timeline: `Legg albumet til dine bilder`,
    change_album_viewMode: 'Endre bildevisning',
    sort_album_files: 'Sorter album',
    sort_album_files_title: 'Sorter album etter',
    sort_album_files_by_added: 'Nylig lagt til',
    sort_album_files_by_newest_first: 'Nyeste først',
    sort_album_files_by_oldest_first: 'Eldste først',
    album_file_count_exceeds_download_limit:
        'Du kan ikke laste ned et album som har mer enn 500 bilder eller videoer.',
    album_size_exceeds_download_limit:
        'Du kan ikke laste ned mer enn 3.5 GB om gangen.',
    // empty album list state
    album_list_is_empty: 'Du har ingen album ennå.',
    empty_albumList_secondTitle: 'Hvorfor ikke lage et album nå?',
    empty_filtered_private_albumList_title: 'Du har ingen private album.',
    empty_filtered_shared_albumList_title: 'Du har ingen delte album.',
    empty_filtered_albumList_subtext:
        'Det er enkelt å opprette et nytt album! Trykk på knappen under for å starte.',
    empty_album_list_read_only_header:
        'Du må ha et abonnement på Min Sky for å lage album.',
    // empty album state
    empty_album_photos: 'Dette albumet har ingen bilder ennå',
    // add to album
    album_select: 'Velg et album',
    // create album
    create_new: 'Lag nytt',
    private_album: 'Privat album',
    shared_album: 'Delt album',
    share_created_album_prompt_header: 'Du har laget et album!',
    share_created_album_prompt_text:
        'Del det med venner og familie, slik at de kan legge til sine egne bilder og kommentarer.',
    share_later: 'Del senere',
    share_now: 'Del nå',
    name_your_album: 'Gi albumet ditt et navn',
    add_photos_to_album: 'Legg til bilder i album',
    create_empty_album: 'Opprett album og legg til bilder senere',
    save_create_album: 'Lagre og lag album',
    set_name_for_shared_album:
        'Brukernavn kreves for delte album, slik at vennene dine vet hvem du er.',
    album_name_is_required_for_shared_albums:
        'Albumnavn kreves for delte album',
    // Uploading to album
    also_upload_to_timeline: 'Last opp til dine bilder?',
    uploading_to_album_name__format: 'Du laster opp til "%album_name%"',
    uploading_to_album_also_to_timeline__format:
        'Skal disse filene også legges til i dine %product_name%-bilder?',
    uploading_files_to_new_album: 'Du laster opp filer til et nytt album.',
    uploading_files_to_multiple_albums__format:
        'Du laster opp filer til %album_count% nye album.',
    // Uploading folder to albums
    create_albums_prompt: 'Opprett album fra mapper?',
    create_album_prompt: 'Opprett et album?',
    also_create_albums__format:
        'Du laster opp en mappe med navn “%album_name%”. Skal vi også lage et album med dette innholdet?',
    also_create_albums_multiple__format:
        'Du laster opp %d mapper. Skal vi også opprette album for disse?',
    // misc album
    fetching_albums: 'Henter album',
    // Comments
    comment: 'kommentar',
    comments: 'Kommentarer',
    write_your_comment: 'Skriv kommentaren din her',
    comment_error_message:
        'En feil oppstod ved kommentering. Vennligst prøv igjen.',
    more: 'Mer',
    edited: 'Redigert',
    edit_comment_error: 'Klarte ikke å redigere kommentar',
    delete_comment_error: 'Klarte ikke å slette kommentar',
    view_all_comments: 'Se alle kommentarer',
    what_is_your_name: 'Hva heter du?',
    how_would_you_continue: 'Hvordan vil du fortsette?',
    hey: 'Hei',
    tap_here_to_enter_name: 'Trykk her for å angi navn',
    click_here_to_enter_name: 'Legg til navn her',
    enter: 'enter',
    register_or_sign_in: 'Registrer deg / Logg inn',
    continue_as_guest: 'Fortsett som gjest',
    provide_name_description__format:
        'La andre deltakere i albumet vite hvem du er ved å logge inn med din %product_name%-konto.',
    year_ago__format: '%d år siden',
    years_ago__format: '%d år siden',
    month_ago__format: '%d måned siden',
    months_ago__format: '%d måneder siden',
    day_ago__format: '%d dag siden',
    days_ago__format: '%d dager siden',
    hour_ago__format: '%d time siden',
    hours_ago__format: '%d timer siden',
    minute_ago__format: '%d minutt siden',
    minutes_ago__format: '%d minutter siden',
    just_now: 'akkurat nå',
    post: 'Send',

    // C A R O U S E L   V I E W
    slideshow: 'Bildefremvisning',
    play: 'Spill av',
    pause: 'Pause',
    previous: 'Forrige',
    next: 'Neste',
    exit_fullscreen: 'Avslutt',
    info: 'Informasjon',

    // F I L E  I N F O
    file_info: 'Filinformasjon',

    // S E T T I N G S
    // profile
    profile_info: 'Profilinformasjon',
    enter_your_name: 'Oppgi ditt navn',
    set_your_name: 'Skriv ditt navn',
    update_profile_picture: 'Oppdater profilbilde',
    // storage
    other_sources: 'andre kilder',
    current_plan: 'gjeldende abonnement',
    storage_management: 'Lagringsinnstillinger',
    manage_subscription: 'Administrer lagring',
    total_storage: 'Total lagringsplass',
    remaining_storage: 'Gjenstående',
    used_storage: 'brukt',
    buy_more_storage: 'Kjøp mer plass',
    buy_more_storage_install_app:
        'På din lokasjon er kjøp av mer lagringsplass kun tilgjengelig fra Min sky appen. Vennligst last ned Min sky, gå til innstillinger og trykk "Kjøp mer plass".',
    buy_more_storage_open_app:
        'På din lokasjon er kjøp av mer lagringsplass kun tilgjengelig fra Min sky appen.',
    connected_devices: 'Tilkoblede enheter',
    unlimited: 'Ubegrenset',
    your_storage_plan: 'Ditt abonnement',
    next_billing_date__format: 'Neste faktureringsdato: %date%',
    expires_date__format:
        'Lagringsabonnementet er stoppet. Lagring utløper %date%',
    plan_is_canceled: 'Du har sagt opp dette abonnementet',
    has_credit:
        'Du har %credit_amount% til gode i %product_name%. Penger du har til gode vil automatisk brukes til framtidige betalinger.',
    manage_plan: 'Organiser',
    storage_used_out_of__format: '%storage_used% brukt av %storage_total%',
    upgrade_storage_plan: 'Oppgrader abonnement',
    cancel_storage_plan: 'Stopp abonnement',
    renew_status__format: 'Abonnementet vil bli fornyet %s',
    expired_status__format: 'Abonnementet utløper %s',
    reactivate_storage_plan: 'Aktiver abonnementet på nytt',
    choose_subscription: 'Velg et abonnement',
    subscribe_to_product: 'Abonner på %product_name%',
    // storage sources
    your_storage: 'Din oppbevaringsplass',
    storage_source_capture: 'Inkludert i %product_name%',
    storage_source_b2b_customer__format: 'Kun for %BU_name%-kunder',
    storage_source_web: 'Kjøpt i nettleser',
    storage_source_apple_store: 'Apple App Store',
    storage_source_googlePlay_store: 'Google Play Store',
    storage_source_connect_store: 'Kjøpt i gammel nettbutikk',
    storage_source_other: 'Andre kilder',
    storage_source_customer_service: 'Bevilget av Kundestøtte',
    storage_source_b2b__format: 'Bevilget av %BU_name%',
    storage_source_starts: 'Starter:',
    storage_source_expires: 'Opphører:',
    storage_source_next_billing: 'Neste betaling:',
    storage_source_resubscribe: 'Gjenoppta',
    storage_source_active: 'Aktive abonnementer',
    storage_source_upcoming: 'Kommende abonnement',
    free__as_in_no_cost: 'Gratis',
    details_unlimited_storage: 'Du har ubegrenset lagring!',
    details_text_unlimited_storage_1__format:
        'Som %BU_name%-kunde har du rett til %product_name%s ubegrensede lagringsplass uten ekstra kostnad, så du trenger aldri å bekymre deg for å gå tom for plass!',
    details_text_unlimited_storage_2__format:
        'Fortsatt nysgjerrig på prisene for brukere som ikke er %BU_name%-kunder? Se under:',
    // payment settings
    payment_settings: 'Betalingsinnstillinger',
    payment_settings_info_text: 'Registrert betalingsinformasjon:',
    change_card_info: 'Endre betalingsinformasjon',
    delete_card: 'Slett kort',
    update_card_info: 'Oppdater kortinformasjon',
    card_number: 'Kortnummer',
    card_exp_date: 'Utløpsdato',
    card_cvc: 'CVC/CVV',
    confirm_payment: 'Bekreft betaling',
    edit_payment_info: 'Endre betalingsinformasjon',
    update_payment_info: 'Oppdater betalingsinformasjon',
    payment_info: 'Betalingsinformasjon',
    payment_method: 'Betalingsmåte',
    pay: 'Betal',
    // Buy more storage
    save__format: 'Spar %d',
    get_started: 'Kom i gang!',
    telenor_business_discount_hook_header: 'Telenor Bedrift kunde?',
    telenor_business_discount_hook:
        'Dersom du er Telenor Bedrift-kunde kan det hende at du får rabatterte priser.',
    telenor_business_discount_hook_link: 'Gå til %telenor_mine_sider%.',
    telenor_mine_sider: 'Telenor Mine Sider',
    mine_sider: 'Mine sider',
    telenor_unlimited_hook__format:
        'Som Telenor Mobil-kunde får du ubegrenset lagringsplass i %product_name%',
    telenor_unlimited_hook_subtext:
        'Gjelder alle private mobilabonnement ekskl. U11, og bedriftsabonnementene Frihet/Flyt/Fri+.',
    monthly_sub: 'Månedsabonnement',
    yearly_sub: 'Årsabonnement',
    email_request_explanation:
        'Epostadressen vil brukes for oppdateringer om ditt lagringsabonnement',
    fetch_storage_plan_failed:
        'Vi kunne ikke laste informasjonen om ditt lagringsabonnement',
    with_free_trial_detail:
        '%num_free_trial% måneders gratis prøveperiode, %price_string% pr %period% etter det',
    downgrade_refund_detail:
        'Du vil få penger til gode i %product_name%, som automatisk trekkes ved framtidig betaling',
    without_free_trial_detail: 'Du betaler %price_string% pr %period%',
    choose_subscription_plan_first: 'Velg et abonnement først',
    choose_subscription_plan_to_see_details:
        'Velg ett av alternativene for å se detaljene',
    choose_subscription_plan_to_start_free_trial:
        'Velg et av abonnementene ovenfor for å starte din gratis prøveperiode',
    change_subscription_plan: 'Endre abonnement',
    subscribe_with_free_trial: 'Få %num_free_trial% måneder gratis bruk',
    proceed_to_pay: 'Fortsett til betaling',
    select_plan_to_see_details: 'Velg et abonnement for å se detaljer.',
    subscribe_to_receive: 'Velg et abonnement og få',
    months_free_trial: '%num_free_trial% måneder gratis bruk',
    learn_more: 'Les mer',
    subscription_notes1:
        'Abonnement med mindre lagringsplass enn nåværende forbruk kan ikke velges',
    change_subscription_period_notice:
        'Du kan ikke bytte tilbake til månedlig betaling etter å ha kjøpt et årsabonnement.',

    // Update popup
    update_pop_header:
        'Vi har gjennomført en sikkerhetsoppdatering i %product_name%-appen.',
    update_pop_body:
        'Vennligst sørg for at du har oppdatert til siste appversjon (%version_number%) i App Store eller Play Store på mobilen din',
    // select language
    language: 'Språk',
    select_language: 'Velg språk',
    // privacy
    privacy_info:
        'Se gjennom personvernet ditt og ta kontroll over innstillingene på samme sted.',
    privacy: 'Personvern',
    privacy_statement: 'Personvernerklæring',
    open_privacy_pages: 'Åpne siden for Personvern',
    consents: 'Tillatelser',
    facial_recognition: 'Ansiktsgjenkjenning',
    privacy_desc__can_process_images_categorisation__format:
        '%company_name% kan prosessere bildene mine for kategorisering av innhold. Dette inkluderer gruppering av lignende ansikter.',
    face_grouping: 'Ansiktsgruppering',
    privacy_desc__can_process_face_details__format:
        '%company_name% kan behandle ansiktsdetaljer i bilder for å gi innholdskategorisering. Dette inkluderer å gruppere lignende ansikter sammen.',
    advanced_filtering_and_categorization:
        'Avansert filtrering og kategorisering',
    privacy_desc__can_process_images_advanced_filtering__format:
        '%company_name% kan behandle innholdet i bilder for å gi funksjoner som avansert filtrering og presentere et sett med bilder som minner.',
    help_us_improve: 'Hjelp oss å bli bedre',
    privacy_desc__can_process_data_for_improvement__format:
        '%company_name% kan prosessere data om hvordan jeg bruker tjenestene deres for å forbedre deres produkter, e.g. hvor ofte jeg bruker applikasjonen.',
    stay_up_to_date: 'Hold deg oppdatert',
    privacy_desc__let_you_know_about_updates:
        'Vi ønsker å fortelle deg om oppdateringer, ny funksjonalitet og nye produkter og tjenester vi tilbyr. Vi ønsker også å sende deg hjelpsomme tips for å forbedre din opplevelse, og andre relevante forslag.',
    can_contact_me_via__format: '%company_name% kan kontakte meg via:',
    push_notification: 'Push-varsel',
    access_your_data: 'Få tilgang til dataene dine',
    privacy_desc__processes_with_privacy_policy__format:
        '%company_name% prosesserer ulike kategorier av brukerdata i henhold til formålene beskrevet i vår %link_start%Personvernerklæring%link_end%.',
    privacy_desc__download_personal_information__format:
        'Du kan laste ned en kopi av personopplysningene vi har om deg her.',
    privacy_request_a_copy_of_data: 'Be om en kopi av dine personopplysninger',
    privacy_download_all:
        'Du kan laste ned alle bildene, videoene og albumene dine fra Min Sky til din Mac/PC. Dette er nyttig når du vil ha alle bildene dine på datamaskinen, overføre dem til en ekstern harddisk eller sikkerhetskopiere dem i en annen skytjeneste.',
    privacy_download_all_button: 'Last ned alt fra Min Sky',
    per_gdpr_right_to_request:
        'I henhold til GDPR har du rett til å forespørre en kopi av all data vi lagrer om deg.',
    if_you_continue_metadata_download__format:
        'Hvis du fortsetter vil vi samle all din metadata og gjøre den tilgjengelig for nedlasting. Du kan kun foreta én forespørsel per %d timer.',
    metadata_recently_downloaded_please_wait__format:
        'Du har nylig lastet ned metadataen din. Du kan kun foreta én forespørsel per %d timer.',

    digital_legacy: 'Digital arv',
    digital_legacy_select_an_option_below:
        'Ved å velge et alternativ nedenfor kan du angi hvordan du ønsker at vi håndterer dine opplastede data ved din bortgang.',
    digital_legacy_default_and_alternative:
        'I utgangspunktet vil vi kun gi tilgang til ditt kontoinnhold til personer navngitt i ditt testamente. Her kan du i tillegg utnevne en kontoarving som vil gis tilgang til ditt kontoinnhold ved din bortgang. Om du ønsker dette, kan du også forespørre at ingen får tilgang.',
    digital_legacy_comply_with_legal_obligation:
        'Merk, vi vil etterkomme alle domstolsbeslutninger eller andre rettslige forpliktelser som pålegger oss å gi tilgang til innholdet på din konto.',
    digital_legacy_only_provide_explicitly_in_will:
        'Kun gi tilgang til innhold på min konto slik det står skrevet i mitt testamente',
    digital_legacy_do_not_provide_content:
        'Ikke gi tilgang til noen ved min bortgang',
    digital_legacy_provide_to_contact:
        'Gi tilgang til innhold på min konto til følgende kontoarving ved min bortgang:',
    digital_legacy_provide_to_contact_modal_title: 'Gi kontoinnhold til:',
    digital_legacy_provide_to_contact_modal_notice:
        'Hvis du nominerer en kontoarving, sender vi dem en e-post med din e-postadresse og telefonnummer for å gi dem beskjed.',
    digital_legacy_provide_to_contact_modal_email_hint:
        'Skriv inn landskode og telefonnummer',
    digital_legacy_provide_to_contact_modal_email_error:
        'Ugyldig e-postadresse',
    digital_legacy_provide_to_contact_phone_error:
        'Oppføring kan bare inneholde "+" og 8 til 14 tall. Vennligst prøv igjen.',

    you_can_request_delete_account__format:
        'Du kan kreve at %company_name% sletter kontoen din, inkludert alt innhold og data som er lagret om deg.',
    delete_account_includes_irreversible_deletion:
        'Dette vil inkludere en irreversibel sletting av alt bilde- og videoinnhold i din Min Sky-konto, så vær sikker på at du har tatt en sikkerhetskopi av det du ønsker å beholde før du foretar denne forespørselen.',
    it_may_take_up_to_days__format:
        'Det kan ta oss opp til %d dager å ferdigstille din forespørsel.',
    request_deletion: 'Forespør sletting',
    delete_account: 'Slett konto',
    delete_your_account: 'Slett Min Sky-kontoen din',
    delete_your_account__info1:
        'Du kan kreve at %company_name% sletter kontoen din, inkludert alt innhold og data som er lagret om deg.',
    delete_your_account__info2:
        'Dette vil inkludere en irreversibel sletting av alt bilde- og videoinnhold i din Min Sky-konto, så vær sikker på at du har tatt en sikkerhetskopi av det du ønsker å beholde før du foretar denne forespørselen.',
    delete_your_account__info3:
        'Det kan ta oss opp til %d dager å ferdigstille din forespørsel.',
    delete_your_account__info4:
        'Hvis du sletter Min Sky-kontoen din, slettes alle bildene og videoene dine permanent, og all annen informasjon på Min Sky-kontoen din.',
    delete_your_account__info5:
        'Dette er irreversibelt, så sørg for at du har en annen sikkerhetskopi av bildene og videoene dine før du fortsetter.',
    delete_your_account__info6:
        'Når du klikker "Slett konto" blir du logget ut av alle enheter.',
    delete_your_account__success1:
        'Din forespørsel om sletting har blitt mottatt.',
    delete_your_account__success2__format:
        'Du har blitt logget ut fra alle enheter, og vi vil prosessere din forespørsel om sletting i løpet av %d dager. Du vil motta en e-post fra oss så snart forespørselen er ekspedert.',
    delete_your_account__failed1:
        'Noe gikk galt da vi prosesserte din forespørsel om sletting.',
    generic_explanation_for_why_it_may_failed:
        'Internettforbindelsen din ble kanskje forstyrret, eller kanskje vi har et midlertidlig problem på våre servere.',
    // TAKEOUT PAGE
    takeout_file: 'fil',
    takeout_files: 'filer',
    capture_settings: 'Min Sky Innstillinger',
    takeout_mobile_text:
        'Denne siden er ikke tilgjengelig på mobil, vennligst bruk Mac/PC.',
    takeout_download_all_title: 'Last ned alle bilder, videoer og album',
    takeout_download_all_body: `Last ned alle bildene, videoene og albumene dine fra
                        Min Sky til din Mac/PC. Dette er nyttig når du vil
                        ha alle filene dine på datamaskinen, overføre dem til
                        en ekstern harddisk, eller sikkerhetskopiere dem på en annen
                        skytjeneste. For spørsmål om nedlasting, besøk
                        %support_url%-siden. For informasjon om våre
                        abonnement, gå til %capture_settings%.`,
    takeout_subheader_photos: 'Min Sky Bilder',
    takeout_body_photos: `Last ned alle bildene og videoene som er sikkerhetskopiert
                        i fanen Bilder. Dette vil inkludere evt
                        dokumenter du kan ha, men inkluderer ikke album. Alle
                        bilder og videoer vil bli lastet ned i original
                        format. For mer informasjon om formater, besøk %support_url%-siden vår.`,
    takeout_subheader_albums: 'Min Sky Album',
    takeout_body_albums: `Nedenfor er en liste over alle de private og delte albumene i
                        fanen Album. Klikk på de du ønsker
                        å laste ned.`,
    takeout_stats: 'Du har %num_files filer (%total_size) i Min Sky Bilder.',
    takeout_download_button: 'Last ned alt fra Min Sky',
    // SUBSCRIBE MODAL
    subscribe_title: 'Abonner på %product_name%',
    subscribe_welcome_text_html__format:
        'Velg et lagringsabonnement i %product_name% og få <span class="freeTrial">%num_free_trial% måneder gratis bruk</span>.',
    subscribe_welcome_text2_html__format:
        'Du kan finne ut mer om %product_name% <a target="_blank" href=%faq_pages% rel="noreferrer">her</a>.',
    subscribe_free: 'Gratis',
    subscribe_sub: 'ABO',
    subscribe_text1: 'Kopier bilder og videoer',
    subscribe_text2: 'Organiser bilder i album',
    subscribe_text3: 'Del bilder og album',
    subscribe_text4: 'Rediger bilder og videoklipp',
    subscribe_text5: 'Bli med i delte album',
    subscribe_accept_tos: 'Jeg godtar %terms_of_service% for Min Sky',
    subscribe_confirm: 'Velg et abonnement',
    subscribe_skip: 'Fortsett uten abonnement',
    subscribe_reconsider: 'Se abonnementsplaner',
    subscribe_error: 'Du må godta vilkårene for bruk',
    no_subscription_title: 'Ingen abonnement',
    no_subscription_limit:
        'Ved å bruke Min Sky uten abonnement kan du bare delta i album andre har delt med deg.',
    no_subscription_reconsider:
        'Du kan kjøpe et abonnement senere eller administrere kontoen din fra Innstillinger.',
    enter_payment_information: 'Fyll inn betalingsinformasjon',
    business_users_html__format:
        'Telenor Bedrift-kunder kan få rabatterte priser. Se <a target="_blank" href=%mine_sider_link% rel="noreferrer">%telenor_mine_sider%</a>.',
    // UNSUBSCRIBE PAGE
    unsubscribe_from_email_form_title: 'Meld deg av Min Skys e-postliste',
    unsubscribe_from_email_form_text1:
        'Vi sender én e-post i måneden med nyttige forslag, tips og nye appfunksjoner.',
    unsubscribe_from_email_form_text2:
        'Er du sikker på at du vil melde deg av e-postlisten vår?',
    unsubscribe_from_email_form_button_stay: 'Bli på listen!',
    unsubscribe_from_email_form_button_unsubscribe: 'Avmeld meg',
    unsubscribe_from_email_success_title: 'Avmelding vellykket!',
    unsubscribe_from_email_success_text:
        'Du kan endre innstillingene dine når som helst i "Personverninnstillinger" i Min Sky.',
    unsubscribe_from_email_stay_title:
        'Innstillingene dine for Min Sky e-post er lagret!',
    unsubscribe_from_email_stay_text:
        'Du kan endre innstillingene dine når som helst i "Personverninnstillinger" i Min Sky.',
    // I N F O
    // Download app page
    thanks_for_signing_in: 'Takk for at du logget inn!',
    get_capture_to_create_album:
        'Last ned Min Sky på mobilen for å lage dine egne album.',
    return_to_album: 'Gå tilbake til albumet',
    // Footer
    telenor_capture_text:
        'Min Sky er utviklet av Telenor Digital, et Telenor-selskap som tilbyr morgendagens digitale løsninger. For å bruke tjenester fra Telenor Digital bruker du din Connect-konto.',
    terms_of_service: 'Brukervilkår',
    customer_service: 'Hjelp og kundestøtte',
    capture_footer_text_mobile: 'Vis i Min Sky appen for best opplevelse',
    open: 'åpne',
    legal: 'Juridisk',
    // Album Not Found
    album_not_found: 'Fant ikke albumet',
    album_not_found_description:
        'Vi fant dessverre ikke albumet! Det er mulig lenken er feilstavet eller har utløpt, eller at albumet er blitt slettet.',
    capture_create_user_description:
        'Min Sky er en trygg og brukervennlig lagringstjeneste for bilder og videoer. Dersom du ikke allerede har Min Sky, last ned appen i dag! ',
    capture_more_link: 'Lær mer om Min Sky',
    // Share Not Found
    share_not_found: 'Finner ikke de delte filene',
    share_not_found_description:
        'Du kan ha klikket på en utgått, slettet eller feilskrevet link.',
    // Page Not Found
    page_not_found: 'Vi kan ikke finne siden du leter etter!',
    oops_page_not_found: 'Oops! Siden finnes ikke.',
    helpful_links_description: 'Her er noen nyttige lenker i steden',
    helpful_links_about_capture: 'Om Min Sky',
    // Provide Password
    password_required: 'Passord påkrevet',
    password_input_placeholder: 'Klikk her for å angi passord',
    password_required_submit: 'enter',
    password_incorrect: 'Feil passord',
    album_password_required_description:
        'Avsenderen har beskyttet dette albumet med et passord. Vennligst oppgi passordet i boksen over for å få tilgang til albumet.',
    // Require login to perform action
    needs_login_subscribe_to_album:
        'Du trenger en %product_name%-konto for å motta oppdatering fra dette albumet',
    needs_connect_to_create_album:
        'Du trenger en %product_name%-konto med et abonnement for å lage album',
    needs_login_to_copy_files_to_timeline:
        'Du trenger en %product_name%-konto med et abonnement for å lagre disse filene',
    needs_login_to_copy_album_to_timeline:
        'Du trenger en %product_name%-konto med et abonnement for å lagre filer fra dette albumet',
    needs_login_to_view_share:
        'Du må være logget inn i %product_name% for å vise denne delingen',
    // Edit/Create album page
    no_title_yet: 'Ingen tittel ennå',
    add_album_title: 'Skriv inn albumtittel',
    // Suggest Modern Browser
    upgrade_your_browser: 'Vennligst oppgrader nettleseren din',
    upgrade_your_browser_des:
        'Beklager! Dette innholdet kan ikke ses i din nåværende nettleser. Oppgrader eller bruk en annen nettleser som Chrome, Firefox eller Microsoft Edge.',
    // Album settings
    share_album: 'Del album',
    edit_album_settings: 'Rediger albuminnstillinger',
    enabled: 'Aktivert',
    disabled: 'Deaktivert',
    privacy_private: 'Privat',
    privacy_shared: 'Delt',
    edit_album_title_text: 'Albumtittel (Trykk for å endre):',
    edit_allow_comments_text: 'Alle kan se og legge til kommentarer',
    edit_allow_upload_text: 'Alle kan legge til bilder',
    edit_privacy_mode_text: 'Personvernnivå',
    // Toasts
    toast__coverPhoto_was_set: 'Forsidebilde endret',
    set_cover_photo_failed_message: 'Kunne ikke sette som forsidebilde.',
    toast__delete_album_failed_message: 'Sletting av albumet feilet.',
    toast__unsubscribe_album_failed_message: 'Kunne ikke forlate albumet.',
    toast__multiple_files_deleted__format: '%d filer slettet',
    toast__single_file_deleted: 'Fil slettet',
    delete_file_failed_message: 'Sletting av filen feilet',
    toast__delete_multiple_files_failed__format: 'Sletting av %d filer feilet',
    toast__multiple_files_perm_deleted__format: '%d filer slettet permanent',
    toast__single_file_perm_deleted: 'Fil permanent slettet',
    toast__multiple_files_perm_deleted_failed__format:
        'Feil - %d filer ble ikke permanent slettet',
    toast__single_file_perm_deleted_failed:
        'Feil - fil ble ikke permanent slettet',
    toast__single_file_restored: 'Fil gjenopprettet',
    toast__multiple_files_restored__format: '%d filer gjenopprettet',
    toast__single_file_restored_failed: 'Feil - fil ikke gjenopprettet',
    toast__multiple_files_restored_failed__format:
        'Feil - %d filer ikke gjenopprettet',
    toast__single_file_added_to_album__format:
        'Fil lagt til i albumet %album_name%',
    toast__multiple_files_added_to_album__format:
        '%d filer lagt til i albumet %album_name%',
    toast__single_file_added_to_album_failed__format:
        'Feil - filen ble ikke lagt til',
    toast__multiple_file_added_to_album_failed__format:
        'Feil - %d filer ble ikke lagt til',
    toast__album_auto_crated__format:
        'Albumet "%album_name%" ble laget for deg',
    toast__see_album: 'Se albumet',
    toast__album_added_to_timeline: 'Album lagt til i dine bilder',
    toast__album_added_to_timeline_failed:
        'Kunne ikke legge til album - prøv igjen',
    toast__album_changed_to_private: 'Album endret til Privat',
    toast__file_added_to_timeline: 'Fil lagt til i Bilder',
    toast__multiple_files_added_to_timeline: 'Filer lagt til i Bilder',
    toast__file_added_to_timeline_failed:
        'Kunne ikke legge til fil - prøv igjen',
    toast__files_were_shared: 'Filene dine ble delt!',
    toast__files_are_deleting: 'Sletter filer...',
    toast__files_are_restoring: 'Gjenoppretter filer...',
    toast__files_are_copying: 'Kopierer filer...',
    toast__preparing_to_download: 'Forbereder nedlasting...',
    toast__credit_card_updated: 'Informasjon om betalingskort er oppdatert',
    toast__credit_card_update_failed: 'Kunne ikke oppdatere informasjon',
    toast__purchase_successful: 'Abonnement kjøpt',
    toast__purchase_failed: 'Kunne ikke kjøpe abonnement',
    toast__plan_canceled: 'Abonnement kansellert',
    toast__plan_cancel_failed: 'Kunne ikke kansellere abonnementet',
    toast__plan_change_succeeded: 'Abonnementet er oppdatert',
    toast__plan_change_failed: 'Kunne ikke oppdatere abonnement',
    toast__plan_reactivated: 'Abonnement reaktivert',
    toast__plan_reactivation_failed: 'Kunne ikke reaktivere abonnementet',
    toast__stripe_setup_intent_created:
        'Abonnement er kjøpt. Kvitteringen din vil bli sendt til %email%',
    toast__delete_user_card_success: 'Betalingskort slettet',
    toast__delete_user_card_failed: 'Kunne ikke slette kortinformasjon',
    toast__change_profile_picture_failed: 'Oppdatering av profilbilde feilet',
    toast__selection_count_limit_reached: 'Du har valgt for mange bilder',
    toast__file_copied_to_clipboard: 'Kopiert til utklippstavla!',
    toast__file_copy_to_clipboard_failed: 'Feil ved kopiering!',
    toast__change_user_name_failed: 'Kunne ikke oppdatere profilnavnet',

    toast__goto_albums: 'Gå til Album',
    toast__album_restored: 'Ditt album er gjenopprettet',
    toast__albums_restored: 'Dine album er gjenopprettet',
    toast__album_restore_failed: 'Återställning av album misslyckades',
    toast__album_deleted: 'Ditt album er slettet permanent',
    toast__albums_deleted: 'Dine album er slettet permanent',
    toast__album_delete_failed: 'Radering av album misslyckades',

    toast__goto_timeline: 'Gå til Bilder',

    toast__changes_saved: 'Endringer lagret',

    // Trash
    photos_and_videos: 'Bilder og videoer',
    trash_deleted: 'Slettede bilder og videoer',
    trash_delete_info:
        'Slettede filer vil bli permanent slettet etter 90 dager.',
    trash_no_files: 'Du har ingen slettede filer.',
    trash_restore_info: 'Du kan gjenopprette dem når som helst før det!',
    deleted_today: 'Slettes i dag',
    permanently_deleted_soon: 'Snart permanent slettet',
    recently_deleted: 'Nylig slettede',
    recently_deleted_albums: 'Nylig slettede album',
    trash_no_albums: 'Du har ingen nylig slettede album.',
    trash_delete_info_albums:
        'Nylig slettede album vil slettes permanent etter 90 dager.',
    album_trash__restore_albums_title: 'Gjenopprett album',
    album_trash__restore_albums_body: 'Vil du gjenopprette disse albumene?',
    album_trash__delete_album_title: 'Slett permanent',
    album_trash__delete_albums_body: 'Vil du slette albumene permanent?',
    album_trash__delete_album_body: 'Vil du slette albumet permanent?',
    photos_trash__restore_all_title: 'Gjenopprett alle bilder og videoer',
    photos_trash__restore_all_body:
        'Vil du gjenopprette alle bilder og videoer tilbake til Min Sky Bilder?',
    photos_trash__restore_all_completed_title:
        'Alle bilder og videoer er gjenopprettet',
    photos_trash__restore_all_completed_body:
        'Alle bildene og videoene dine er tilbake i Min Sky Bilder.',
    photos_trash__restore_all_incomplete_title: 'Ufullstendig gjenoppretting',
    photos_trash__restore_all_incomplete_single_file_body:
        'En fil kunne ikke gjenopprettes. Resten av bildene og videoene er tilbake i Min Sky Bilder.',
    photos_trash__restore_all_incomplete_multiple_files_body:
        '%count% filer kunne ikke gjenopprettes. Resten av bildene og videoene er tilbake i Min Sky Bilder.',

    photos_trash__restoring_all: 'Gjenoppretter alle bilder og videoer',
    photos_trash__keep_open: 'Vennligst ikke lukk denne siden.',
    photos_trash__keep_open_app: 'Vennligst ikke lukk appen.',
    photos_trash__download_all_title: 'Last ned alle bilder og videoer',
    photos_trash__download_all_body:
        'Vil du laste ned alle bilder og videoer til maskinen din?',
    photos_trash__download_all_body_app:
        'Vil du laste ned alle bilder og videoer til enheten din?',

    // Documents
    documents_no_files: 'Du har ingen dokumenter.',
    // Share files popup
    share_as_album: 'Del som album',
    share_album_explanation:
        'Opprett et album med alle bildene du ønsker å dele. Alle du deler det med kan like, kommentere og legge til egne bilder.',
    share_get_link_explanation:
        'Del bilder med en midlertidlig lenke. Lenken utløper etter 30 dager.',
    share_select_how_to: 'Velg hvordan du ønsker å dele',
    share_album_created_link:
        'Album opprettet! Bruk et av alternativene under for å dele albumet:',
    share_disclaimer:
        'Når du deler et album er det synlig for alle som har lenken, selv om du ikke personlig har invitert dem.',
    my_new_shared_album: 'Mitt nye delte album',
    share_to: 'Del via',
    email: 'E-post',
    SMS: 'SMS',
    get_link: 'Få link',
    hold_to_copy_link: 'Trykk og hold for å kopiere linken',
    copy_link: 'Kopier lenke',
    link_copied: 'Link ble kopiert!',
    share_files_success_header: 'Du har delt et album!',
    can_not_share_video_to_fb_album:
        'OBS! Videoer kan ikke deles i Facbook-album',
    share_stop_sharing: 'Ønsker du å stoppe delingen av disse filene?',
    share_login_to_stop_sharing:
        'Hvis du har delt disse filene og ønsker å stoppe delingen av dem, må du først %link_start%logge inn%link_end%.',
    // share album form
    allow_others_comment: 'Tillat at andre kommenterer på bildene',
    allow_others_upload: 'Tillat at andre laster opp bilder',
    // email sharing form
    send_email_to: 'Send e-post til:',
    enter_receivers_address: 'Skriv inn mottakers adresse her',
    email_subject: 'Emne:',
    enter_subject: 'Skriv inn emne her',
    email_message: 'Melding (valgfritt):',
    enter_message: 'Skriv inn meldingen her',
    invalid_input: 'Ugyldig',
    // S H A R E   R E C E I V E R
    share__header_SingleFile__format:
        '%user_name% har delt en fil med deg ved hjelp av %product_name%',
    share__header_MultipleFiles__format:
        '%user_name% har delt noen filer med deg ved hjelp av %product_name%',
    share__header_SingleFile_owner__format:
        'Du har delt en fil ved hjelp av %product_name%',
    share__header_MultipleFiles_owner__format:
        'Du har delt noen filer ved hjelp av %product_name%',
    share__add_singleFile_to_capture__format: 'Legg til bilde i %s',
    share__add_multipleFiles_to_capture__format: 'Legg til bilder i %s',
    download_all: 'Last ned alle',
    share_added_to_timeline: 'Filene ble lagt til',
    share_added_to_timeline_failed:
        'Kunne ikke legge til filer - vennligst prøv igjen',
    share__stop: 'Stopp deling',
    share__confirm_stop: 'Er du sikker på du vil stoppe deling?',
    share__deletion_failed: 'Kunne ikke stoppe deling',
    share__deleted_SingleFile: 'Filen er ikke lenger delt',
    share__deleted_MultipleFiles: 'Filene er ikke lenger delt',
    share_failed: 'Kunne ikke dele filer - vennligst prøv igjen',
    share__not_capture_user: 'Ikke en Min Sky-bruker?',
    share__try_capture: 'Klikk her for å prøve!',
    share__service_info_part1__format:
        '%product_name% er laget for å holde bildene og videoene dine trygge! Det er den enkleste måten å automatisk sikkerhetskopiere bilder og videoer fra mobilen, og du har tilgang til bildene dine på alle enheter, hvor og når du vil.',
    share__service_info_part2__format:
        'Registrer deg i %product_name% og få 2 måneder gratis. Om du er mobilkunde hos Telenor Norge, er det sannsynlig at du har ubegrenset lagringsplass i %product_name% inkludert i abonnementet.',
    share__service_info_part2__minSky__format:
        'Registrer deg i %product_name% og få 2 måneder gratis. Om du har privat mobilabonnement eller Frihet/Flyt/Fri+ bedriftsabonnement hos Telenor Norge, har du gratis ubegrenset lagringsplass i %product_name% inkludert. Send SMS til 1999 om gratis lagringsplass ikke er aktivert.',
    share__service_info_footer__format:
        'Gjelder alle private mobilabonnement og Frihet, Flyt og Fri+ bedriftsabonnement.',
    // Password required
    share_password_required_description:
        'Avsenderen har beskyttet det delte innholdet med et passord. Vennligst oppgi passordet i boksen over for å få tilgang til innholdet.',
    // Select from external page
    choose_photo_selection_source: 'Velg bilder fra ',
    all_photos: 'Alle bilder',
    // user hints
    hint_longpress_show_full_image:
        'Trykk og hold på bildet for å se stor versjon',
    editor_hint_body: 'Legg til filtre, beskjær bilder og mer.',
    // Tooltip
    readonly_tooltip: 'Abonnement kreves for full tilgang.',
    // Sunset
    deletion_prompt__title: 'Endringer i Min Sky-vilkårene dine',
    deletion_prompt__body:
        'Fra %delete_date% vil Min Sky kun tilbys som en betalingstjeneste. For å fortsette å bruke Min Sky og beholde dine opplastede bilder og videoer, se våre lagringsabonnement. Du får kostnadsfri bruk de 6 første månedene.',
    deletion_prompt__more_info: 'Mer informasjon',
    deletion_prompt__plans: 'Abonnement',
    // casting
    cast: 'Cast',
    casting_to: 'Kaster til %s',
    // consent
    cookie_explanation__format:
        '%product_name% bruker informasjonskapsler for å analysere og forbedre brukeropplevelsen. Noe av den innsamlede informasjonen brukes av tredjeparter.',
    cookie_explanaion_link__format:
        'Les vår %link_start%Personvernerklæring%link_end% for å lære mer om vår datahåndtering.',
    account_terminated_error: 'Denne kontoen er avsluttet',
    account_terminated_desc__format:
        'Kontoen har blitt avsluttet av brukeren og er derfor ikke lenger tilgjengelig. Vennligst kontakt %link_start%kundeservice%link_end% med spørsmål eller behov for hjelp.',
    // PhotoEditorSDK
    common_error: 'Feil',
    common_warning: 'Advarsel',
    common_color_colorPicker_hex: 'Hex',
    common_color_colorPicker_r: 'R',
    common_color_colorPicker_g: 'G',
    common_color_colorPicker_b: 'B',
    common_color_colorPicker_sliderHue: 'Farge',
    common_color_colorPicker_sliderOpacity: 'Fargeopasitet',
    mainCanvasActions_buttonExport: 'Lagre',
    mainCanvasActions_buttonUndo: 'Angre',
    mainCanvasActions_buttonRedo: 'Gjør om',
    mainCanvasActions_buttonClose: 'Lukk',
    infoModals_exporting_heading: 'Eksporterer...',
    infoModals_exporting_body: 'Et øyeblikk...',
    infoModals_saving_heading: 'Lagrer...',
    infoModals_saving_body: 'Et øyeblikk...',
    infoModals_loading_heading: 'Laster...',
    infoModals_loading_body: 'Et øyeblikk...',
    infoModals_resizing_heading: 'Endrer størrelse...',
    infoModals_resizing_body: 'Et øyeblikk...',
    infoModals_loadingFonts_heading: 'Laster inn skrifter...',
    infoModals_loadingFonts_body: 'Et øyeblikk...',
    infoModals_stickerLoading_heading: 'Laster klistremerke...',
    infoModals_stickerLoading_body: 'Et øyeblikk...',
    errorModals_imageLoading_headingDelimiter: ':',
    errorModals_imageLoading_body:
        'Kunne ikke laste inn bildet. Dette kan ha flere årsaker, f.eks. filen er ødelagt eller filtypen støttes ikke',
    errorModals_imageLoading_buttonYes: 'Prøv igjen',
    errorModals_imageLoading_buttonNo: 'Lukk',
    errorModals_rendering_headingDelimiter: ':',
    errorModals_rendering_body:
        'Det har oppstått en feil under gjengivelsen av bildet',
    errorModals_rendering_buttonYes: 'Prøv igjen',
    errorModals_rendering_buttonNo: 'Lukk',
    errorModals_fontLoading_headingDelimiter: ':',
    errorModals_fontLoading_heading: 'Kunne ikke laste inn skrift',
    errorModals_fontLoading_body:
        'Følgende fonter kunne ikke lastes inn: ${error}',
    errorModals_fontLoading_buttonNo: 'Lukk',
    errorModals_webcamUnavailable_headingDelimiter: ':',
    errorModals_webcamUnavailable_body:
        'Kan ikke vise webkamerabilde (Feil: ${error})',
    errorModals_webcamUnavailable_buttonYes: 'Lukk',
    errorModals_stickerLoading_headingDelimiter: ':',
    errorModals_stickerLoading_body: 'Kan ikke laste inn klistremerket',
    errorModals_stickerLoading_buttonNo: 'Lukk',
    errorModals_unexpectedError_headingDelimiter: ':',
    errorModals_unexpectedError_body:
        'Det har oppstått en uventet feil ${error}',
    errorModals_unexpectedError_buttonYes: 'Prøv igjen',
    errorModals_unexpectedError_buttonNo: 'Lukk',
    warningModals_imageResized_headingDelimiter: ':',
    warningModals_imageResized_heading: 'Bilde endret størrelse',
    warningModals_imageResized_body:
        'Bildet ditt overskrider maksimalstørrelsen på ${megapixels} megapiksler og har blitt endret til ${width}x${height} piksler',
    warningModals_imageResized_buttonYes: 'Fortsett',
    warningModals_unsupportedSerializationVersion_headingDelimiter: ':',
    warningModals_unsupportedSerializationVersion_body:
        'Dataen din ble gjenopprettet fra et eldre dataformat og kan se annerledes ut',
    warningModals_unsupportedSerializationVersion_buttonYes: 'Bruk endringer',
    warningModals_unsupportedSerializationVersion_buttonNo: 'Avbryt',
    warningModals_discardChanges_headingDelimiter: ':',
    warningModals_discardChanges_body:
        'Du har ulagrede endringer. Er du sikker på at du vil forkaste endringene?',
    warningModals_discardChanges_buttonYes: 'Forkast endringer',
    warningModals_discardChanges_buttonNo: 'Behold endringer',
    warningModals_unsavedChanges_headingDelimiter: ':',
    warningModals_unsavedChanges_body:
        'Du har ulagrede endringer. Er du sikker på at du vil avslutte?',
    warningModals_unsavedChanges_buttonYes: 'Avslutt uten å lagre',
    warningModals_unsavedChanges_buttonNo: 'Avbryt',
    warningModals_unsupportedWebGLRenderer_body:
        'Det er oppdaget et problem i nettleseren som kan føre til lange lastetider. Prøv en annen nettleser.',
    warningModals_unsupportedWebGLRenderer_buttonYes: 'Fortsett',
    library_title: 'Bibliotek',
    library_controls_buttonUpload: 'Last opp bilde',
    library_controls_buttonWebcamOpen: 'Åpne webkamera',
    library_controls_buttonWebcamClose: 'Lukk webkamera',
    library_controls_placeholderSearch: 'Søk i biblioteket',
    library_controls_noResults: 'Ingen resultater',
    filter_title: 'Filtre',
    filter_controls_buttonReset: 'Fjern filter',
    filter_controls_sliderIntensity: 'Filterintensitet',
    filter_categories_imgly_filter_category_duotone: 'Duotone',
    filter_categories_imgly_filter_category_bw: 'B & W',
    filter_categories_imgly_filter_category_vintage: 'Vintage',
    filter_categories_imgly_filter_category_smooth: 'Glatt',
    filter_categories_imgly_filter_category_cold: 'Kald',
    filter_categories_imgly_filter_category_warm: 'Varm',
    filter_categories_imgly_filter_category_legacy: 'Arv',
    filter_items_imgly_lut_celsius: 'Skjærsild',
    filter_items_imgly_lut_chest: 'Kastanje',
    filter_items_imgly_lut_fixie: 'Fixie',
    filter_items_imgly_lut_fridge: 'Kjøleskap',
    filter_items_imgly_lut_front: 'Det glade 70-tallet',
    filter_items_imgly_lut_k2: 'Matt svart',
    filter_items_imgly_lut_mellow: 'Rolig',
    filter_items_imgly_lut_sin: 'Harde ting',
    filter_items_imgly_lut_texas: 'Veteran',
    filter_items_imgly_lut_ad1920: '1920 A.D.',
    filter_items_imgly_lut_ancient: 'Elgammelt',
    filter_items_imgly_lut_bleached: 'Kalmen',
    filter_items_imgly_lut_bleachedblue: 'Joran',
    filter_items_imgly_lut_blues: 'Polaroid',
    filter_items_imgly_lut_blueshadows: 'Zephyr',
    filter_items_imgly_lut_breeze: 'Levante',
    filter_items_imgly_lut_bw: 'Greyed',
    filter_items_imgly_lut_classic: 'Klassisk',
    filter_items_imgly_lut_colorful: 'Fargerik',
    filter_items_imgly_lut_cool: 'Tjapp',
    filter_items_imgly_lut_cottoncandy: 'Godteri',
    filter_items_imgly_lut_creamy: 'Kremete',
    filter_items_imgly_lut_eighties: 'Lav ild',
    filter_items_imgly_lut_elder: 'Colla',
    filter_items_imgly_lut_evening: 'Sunrise',
    filter_items_imgly_lut_fall: 'Moss',
    filter_items_imgly_lut_food: 'Food',
    filter_items_imgly_lut_glam: 'Glam',
    filter_items_imgly_lut_gobblin: 'Gobblin',
    filter_items_imgly_lut_highcarb: 'High Carb',
    filter_items_imgly_lut_highcontrast: 'Hicon',
    filter_items_imgly_lut_k1: 'K1',
    filter_items_imgly_lut_k6: 'K6',
    filter_items_imgly_lut_kdynamic: 'Pebble',
    filter_items_imgly_lut_keen: 'Keen',
    filter_items_imgly_lut_lenin: 'Lemon',
    filter_items_imgly_lut_litho: 'Litho',
    filter_items_imgly_lut_lomo: 'Lomo',
    filter_items_imgly_lut_lomo100: 'Lomo 100',
    filter_items_imgly_lut_lucid: 'Våken',
    filter_items_imgly_lut_neat: 'Ryddig',
    filter_items_imgly_lut_nogreen: 'Gresskar',
    filter_items_imgly_lut_orchid: 'Solanus',
    filter_items_imgly_lut_pale: 'Blek',
    filter_items_imgly_lut_pitched: 'Kastet',
    filter_items_imgly_lut_plate: 'Forvitret',
    filter_items_imgly_lut_pola669: 'Grønnt opprom',
    filter_items_imgly_lut_polasx: 'Pola SX',
    filter_items_imgly_lut_pro400: 'Pro 400',
    filter_items_imgly_lut_quozi: 'Quozi',
    filter_items_imgly_lut_sepiahigh: 'Sepia',
    filter_items_imgly_lut_settled: 'Avklart',
    filter_items_imgly_lut_seventies: '70-tallet',
    filter_items_imgly_lut_soft: 'Myk',
    filter_items_imgly_lut_steel: 'Stål',
    filter_items_imgly_lut_summer: 'Sommer',
    filter_items_imgly_lut_sunset: 'Gylden',
    filter_items_imgly_lut_tender: 'Mør',
    filter_items_imgly_lut_twilight: 'Skumring',
    filter_items_imgly_lut_winter: 'Myk',
    filter_items_imgly_lut_x400: 'Støvete',
    filter_items_imgly_duotone_desert: 'Ørken',
    filter_items_imgly_duotone_peach: 'Fersken',
    filter_items_imgly_duotone_clash: 'Sammenstøt',
    filter_items_imgly_duotone_plum: 'Plomme',
    filter_items_imgly_duotone_breezy: 'Luftig',
    filter_items_imgly_duotone_deepblue: 'Dyp blå',
    filter_items_imgly_duotone_frog: 'Frosk',
    filter_items_imgly_duotone_sunset: 'Solnedgang',
    adjustment_title: 'Juster',
    adjustment_controls_buttonReset: 'Tilbakestill justering',
    adjustment_categories_basics: 'Grunnleggende',
    adjustment_categories_refinements: 'Avgrensninger',
    adjustment_items_brightness: 'Lysstyrke',
    adjustment_items_saturation: 'Metning',
    adjustment_items_contrast: 'Kontrast',
    adjustment_items_gamma: 'Gamma',
    adjustment_items_sharpness: 'Skarphet',
    adjustment_items_clarity: 'Klarhet',
    adjustment_items_exposure: 'Eksponering',
    adjustment_items_shadows: 'Skygger',
    adjustment_items_highlights: 'Lyse områder',
    adjustment_items_whites: 'Hvitpunkt',
    adjustment_items_blacks: 'Svartpunkt',
    adjustment_items_temperature: 'Temperatur',
    focus_title: 'Uskarphet',
    focus_controls_buttonReset: 'Fjern fokus',
    focus_controls_sliderIntensity: 'Fokusintensitet',
    focus_items_radial: 'Radial',
    focus_items_mirrored: 'Speilvendt',
    focus_items_linear: 'Lineær',
    focus_items_gaussian: 'Gaussisk',
    focus_history_focusPosition: 'Fokusposisjon',
    focus_history_focusSize: 'Fokusstørrelse',
    overlay_title: 'Overlegg',
    overlay_controls_buttonReset: 'Fjern overlegg',
    overlay_controls_sliderOpacity: 'Overleggsopasitet',
    overlay_controls_carouselBlendMode: 'Overleggsblandingsmodus',
    overlay_controls_blendModeNormal: 'Normal',
    overlay_controls_blendModeOverlay: 'Overlegg',
    overlay_controls_blendModeHardLight: 'Hardt lys',
    overlay_controls_blendModeSoftLight: 'Mykt lys',
    overlay_controls_blendModeMultiply: 'Multiplisere',
    overlay_controls_blendModeDarken: 'Mørkere',
    overlay_controls_blendModeLighten: 'Lysne',
    overlay_controls_blendModeScreen: 'Skjerm',
    overlay_controls_blendModeColorBurn: 'Fargebrenning',
    overlay_controls_tabOpacity: 'Gjennomsiktighet',
    overlay_controls_tabBlendMode: 'Blandingsmodus',
    overlay_items_imgly_overlay_bokeh: 'Bokeh',
    overlay_items_imgly_overlay_chop: 'Kutt',
    overlay_items_imgly_overlay_clouds: 'Skyer',
    overlay_items_imgly_overlay_golden: 'Gylden',
    overlay_items_imgly_overlay_grain: 'Korn',
    overlay_items_imgly_overlay_hearts: 'Hjerter',
    overlay_items_imgly_overlay_lightleak1: 'Light Leak 1',
    overlay_items_imgly_overlay_lightleak2: 'Light Leak 2',
    overlay_items_imgly_overlay_metal: 'Metall',
    overlay_items_imgly_overlay_mosaic: 'Mosaikk',
    overlay_items_imgly_overlay_painting: 'Maleri',
    overlay_items_imgly_overlay_paper: 'Papir',
    overlay_items_imgly_overlay_rain: 'Regn',
    overlay_items_imgly_overlay_vintage: 'Vintage',
    overlay_items_imgly_overlay_wall1: 'Vegg',
    overlay_items_imgly_overlay_wall2: 'Vegg 2',
    overlay_items_imgly_overlay_wood: 'Tre',
    sticker_title: 'Klistremerker',
    sticker_controls_buttonUpload: 'Last opp klistremerke',
    sticker_controls_sliderOpacity: 'Klistremerkeopasitet',
    sticker_controls_selectColor: 'Klistremerkefarge',
    sticker_controls_tabColor: 'Farge',
    sticker_controls_tabOpacity: 'Gjennomsiktighet',
    sticker_categories_imgly_sticker_emoticons: 'Uttrykksikoner',
    sticker_categories_imgly_sticker_shapes: 'Former',
    sticker_categories_imgly_sticker_custom: 'Tilpass',
    sticker_items_imgly_sticker_emoticons_alien: 'Romvesen',
    sticker_items_imgly_sticker_emoticons_angel: 'Engel',
    sticker_items_imgly_sticker_emoticons_angry: 'Sint',
    sticker_items_imgly_sticker_emoticons_anxious: 'Engstelig',
    sticker_items_imgly_sticker_emoticons_asleep: 'Sovende',
    sticker_items_imgly_sticker_emoticons_attention: 'Oppmerksomhet',
    sticker_items_imgly_sticker_emoticons_baby_chicken: 'Babykylling',
    sticker_items_imgly_sticker_emoticons_batman: 'Batman',
    sticker_items_imgly_sticker_emoticons_beer: 'Øl',
    sticker_items_imgly_sticker_emoticons_black: 'Svart',
    sticker_items_imgly_sticker_emoticons_blue: 'Blå',
    sticker_items_imgly_sticker_emoticons_blush: 'Rødme',
    sticker_items_imgly_sticker_emoticons_boxer: 'Bokser',
    sticker_items_imgly_sticker_emoticons_business: 'Foretak',
    sticker_items_imgly_sticker_emoticons_chicken: 'Kylling',
    sticker_items_imgly_sticker_emoticons_cool: 'Kult',
    sticker_items_imgly_sticker_emoticons_cry: 'Gråt',
    sticker_items_imgly_sticker_emoticons_deceased: 'Avdød',
    sticker_items_imgly_sticker_emoticons_devil: 'Djevel',
    sticker_items_imgly_sticker_emoticons_duckface: 'Andefjes',
    sticker_items_imgly_sticker_emoticons_furious: 'Rasende',
    sticker_items_imgly_sticker_emoticons_grin: 'Glise',
    sticker_items_imgly_sticker_emoticons_guitar: 'Gitar',
    sticker_items_imgly_sticker_emoticons_harry_potter: 'Harry Potter',
    sticker_items_imgly_sticker_emoticons_hippie: 'Hippie',
    sticker_items_imgly_sticker_emoticons_hitman: 'Leiemorder',
    sticker_items_imgly_sticker_emoticons_humourous: 'Humoristisk',
    sticker_items_imgly_sticker_emoticons_idea: 'Idé',
    sticker_items_imgly_sticker_emoticons_impatient: 'Utolmodig',
    sticker_items_imgly_sticker_emoticons_kiss: 'Kyss',
    sticker_items_imgly_sticker_emoticons_kisses: 'Kysser',
    sticker_items_imgly_sticker_emoticons_laugh: 'Latter',
    sticker_items_imgly_sticker_emoticons_loud_cry: 'Høyt rop',
    sticker_items_imgly_sticker_emoticons_loving: 'Kjærlig',
    sticker_items_imgly_sticker_emoticons_masked: 'Maskert',
    sticker_items_imgly_sticker_emoticons_music: 'Musikk',
    sticker_items_imgly_sticker_emoticons_nerd: 'Nerd',
    sticker_items_imgly_sticker_emoticons_ninja: 'Ninja',
    sticker_items_imgly_sticker_emoticons_not_speaking_to_you: 'Snakkes, aldri',
    sticker_items_imgly_sticker_emoticons_pig: 'Gris',
    sticker_items_imgly_sticker_emoticons_pumpkin: 'Gresskar',
    sticker_items_imgly_sticker_emoticons_question: 'Spørsmål',
    sticker_items_imgly_sticker_emoticons_rabbit: 'Kanin',
    sticker_items_imgly_sticker_emoticons_sad: 'Trist',
    sticker_items_imgly_sticker_emoticons_sick: 'Syk',
    sticker_items_imgly_sticker_emoticons_skateboard: 'Rullebrett',
    sticker_items_imgly_sticker_emoticons_skull: 'Hodeskalle',
    sticker_items_imgly_sticker_emoticons_sleepy: 'Søvnig',
    sticker_items_imgly_sticker_emoticons_smile: 'Smil',
    sticker_items_imgly_sticker_emoticons_smoking: 'Røyking',
    sticker_items_imgly_sticker_emoticons_sobbing: 'Hulkende',
    sticker_items_imgly_sticker_emoticons_star: 'Stjerne',
    sticker_items_imgly_sticker_emoticons_steaming_furious: 'Dampende rasende',
    sticker_items_imgly_sticker_emoticons_sunbathing: 'Soling',
    sticker_items_imgly_sticker_emoticons_tired: 'Sliten',
    sticker_items_imgly_sticker_emoticons_tongue_out_wink: 'Tunge ut med blunk',
    sticker_items_imgly_sticker_emoticons_wave: 'Vinke',
    sticker_items_imgly_sticker_emoticons_wide_grin: 'Bredt smil',
    sticker_items_imgly_sticker_emoticons_wink: 'Vinke',
    sticker_items_imgly_sticker_emoticons_wrestler: 'Bryter',
    sticker_items_imgly_sticker_shapes_arrow_02: 'Pil 1',
    sticker_items_imgly_sticker_shapes_arrow_03: 'Pil 2',
    sticker_items_imgly_sticker_shapes_badge_01: 'Skilt 1',
    sticker_items_imgly_sticker_shapes_badge_11: 'Skilt 5',
    sticker_items_imgly_sticker_shapes_badge_12: 'Skilt 6',
    sticker_items_imgly_sticker_shapes_badge_13: 'Skilt 7',
    sticker_items_imgly_sticker_shapes_badge_15: 'Skilt 8',
    sticker_items_imgly_sticker_shapes_badge_18: 'Skilt 9',
    sticker_items_imgly_sticker_shapes_badge_19: 'Skilt 10',
    sticker_items_imgly_sticker_shapes_badge_20: 'Skilt 11',
    sticker_items_imgly_sticker_shapes_badge_28: 'Skilt 12',
    sticker_items_imgly_sticker_shapes_badge_32: 'Skilt 13',
    sticker_items_imgly_sticker_shapes_badge_35: 'Skilt 14',
    sticker_items_imgly_sticker_shapes_badge_36: 'Skilt 15',
    sticker_items_imgly_sticker_shapes_badge_04: 'Skilt 2',
    sticker_items_imgly_sticker_shapes_badge_06: 'Skilt 3',
    sticker_items_imgly_sticker_shapes_badge_08: 'Skilt 4',
    sticker_items_imgly_sticker_shapes_spray_01: 'Spray 1',
    sticker_items_imgly_sticker_shapes_spray_03: 'Spray 2',
    sticker_items_imgly_sticker_shapes_spray_04: 'Spray 3',
    sticker_canvasActions_buttonDelete: 'Slett',
    sticker_canvasActions_buttonBringToFront: 'Flytt frem',
    sticker_canvasActions_buttonDuplicate: 'Duplisere',
    sticker_canvasActions_buttonFlipHorizontal: 'Vend',
    sticker_canvasActions_buttonFlipVertical: 'Vend',
    sticker_history_add: 'Klistremerke',
    sticker_history_resize: 'Endre størrelse på klistremerke',
    sticker_history_position: 'Klistremerkeposisjon',
    sticker_history_color: 'Klistremerkefarge',
    sticker_history_delete: 'Endre størrelse på klistremerke',
    sticker_history_order: 'Rekkefølge klistermerker',
    sticker_history_opacity: 'Klistremerkeopasitet',
    sticker_history_flip: 'Snu klistermerke',
    text_title: 'Tekst',
    text_controls_buttonNew: 'Ny tekst',
    text_controls_dropdownFontFamily: 'Skriftstil',
    text_controls_textFontSize: 'Skriftstørrelse',
    text_controls_selectAlignment: 'Justering',
    text_controls_selectFontColor: 'Tekstfarge',
    text_controls_selectBackgroundColor: 'Bakgrunnsfarge',
    text_controls_sliderLineSpacing: 'Linjeavstand',
    text_controls_tabColor: 'Farge',
    text_controls_tabBgColor: 'Bakgrunn',
    text_controls_tabAlignment: 'Justering',
    text_controls_tabLineHeight: 'Linjehøyde',
    text_controls_tabFontSize: 'Skriftstørrelse',
    text_canvasControls_placeholderText: 'Skriv noe',
    text_canvasControls_buttonSave: 'Fullfør',
    text_canvasControls_buttonClose: 'Avbryt',
    text_canvasControls_inputText: 'Tekstinntasting',
    text_canvasActions_buttonEdit: 'Rediger',
    text_canvasActions_buttonDelete: 'Slett',
    text_canvasActions_buttonBringToFront: 'Flytt frem',
    text_canvasActions_buttonDuplicate: 'Duplisere',
    text_history_add: 'Tekst',
    text_history_edit: 'Tekstredigering',
    text_history_resize: 'Endre størrelse på tekst',
    text_history_position: 'Tekstposisjon',
    text_history_alignment: 'Tekstjustering',
    text_history_textColor: 'Tekstfarge',
    text_history_backgroundColor: 'Tekstbakgrunnsfarge',
    text_history_fontFamily: 'Skriftstil',
    text_history_fontStyle: 'Skriftsstil',
    text_history_lineSpacing: 'Linjeavstand',
    text_history_width: 'Tekstbredde',
    text_history_delete: 'Slett tekst',
    text_history_order: 'Tekstrekkefølge',
    textdesign_title: 'Tekstdesign',
    textdesign_controls_buttonNew: 'Ny tekstdesign',
    textdesign_controls_buttonShuffle: 'Tilfeldig oppsett',
    textdesign_controls_selectColor: 'Tekstfarge',
    textdesign_controls_tabColor: 'Farge',
    textdesign_controls_tabShuffle: 'Tilfeldig rekkefølge',
    textdesign_canvasControls_placeholderText: 'Skriv noe med stil',
    textdesign_canvasControls_buttonSave: 'Fullfør',
    textdesign_canvasControls_buttonClose: 'Lukk',
    textdesign_canvasControls_inputText: 'Tekstinntasting',
    textdesign_canvasActions_buttonEdit: 'Rediger',
    textdesign_canvasActions_buttonInvert: 'Inverter',
    textdesign_canvasActions_buttonDelete: 'Slett',
    textdesign_canvasActions_buttonBringToFront: 'Flytt frem',
    textdesign_canvasActions_buttonDuplicate: 'Duplisere',
    textdesign_history_add: 'Tekstdesign',
    textdesign_history_edit: 'Rediger tekstdesign',
    textdesign_history_resize: 'Endre størrelse på tekstdesign',
    textdesign_history_position: 'Posisjon tekstdesign',
    textdesign_history_color: 'Tekstdesignfarge',
    textdesign_history_shuffle: 'Tilfeldig tekstdesign',
    textdesign_history_invert: 'Inverter tekstdesign',
    textdesign_history_padding: 'Utfylling av tekstdesign',
    textdesign_history_order: 'Rekkefølge tekstdesign',
    textdesign_history_delete: 'Slett tekstdesign',
    frame_title: 'Rammer',
    frame_controls_buttonReset: 'Fjern ramme',
    frame_controls_sliderOpacity: 'Rammeopasitet',
    frame_controls_sliderSize: 'Rammestørrelse',
    frame_controls_selectColor: 'Rammefarge',
    frame_controls_tabColor: 'Farge',
    frame_controls_tabOpacity: 'Gjennomsiktighet',
    frame_controls_tabSize: 'Størrelse',
    frame_items_imgly_frame_dia: 'Dia',
    frame_items_imgly_frame_art_decor: 'Kunstdekor',
    frame_items_imgly_frame_black_passepartout: 'Svart',
    frame_items_imgly_frame_lowpoly_shadow: 'Low Poly',
    frame_items_imgly_frame_wood_passepartout: 'Tre',
    brush_title: 'Pensel',
    brush_controls_buttonReset: 'Fjern pensel',
    brush_controls_sliderSize: 'Penselstørrelse',
    brush_controls_sliderHardness: 'Penselskarphet',
    brush_controls_selectColor: 'Børstefarge',
    brush_controls_tabSize: 'Størrelse',
    brush_controls_tabHardness: 'Skarphet',
    brush_controls_tabColor: 'Farge',
    brush_history_brushStroke: 'Penselstrøk',
    transform_title: 'Beskjær',
    transform_controls_buttonReset: 'Tilbakestill',
    transform_controls_checkboxKeepResolution: 'Behold oppløsning',
    transform_controls_inputCropSize: 'Beskjær',
    transform_controls_inputHeight: 'h',
    transform_controls_inputWidth: 'v',
    transform_controls_tabFlipAndRotate: 'Vend og roter',
    transform_controls_tabResolution: 'Oppløsning',
    transform_controls_tabCropSize: 'Beskjær',
    transform_categories_imgly_transforms_common: 'Vanlig',
    transform_categories_imgly_transforms_facebook: 'Facebook',
    transform_categories_imgly_transforms_twitter: 'Twitter',
    transform_categories_imgly_transforms_instagram: 'Instagram',
    transform_items_imgly_transform_common_custom: 'Tilpass',
    transform_items_imgly_transform_common_square: 'Kvadrat',
    transform_items_imgly_transform_common_4: '4:3',
    transform_items_imgly_transform_common_16: '16:9',
    transform_items_imgly_transform_common_3: '3:4',
    transform_items_imgly_transform_common_9: '9:16',
    transform_items_imgly_transform_facebook_profile: 'Profil',
    transform_items_imgly_transform_facebook_title: 'Tittel',
    transform_items_imgly_transform_facebook_post: 'Send',
    transform_items_imgly_transform_instagram_story: 'Historie',
    transform_items_imgly_transform_instagram_landscape: 'Landskap',
    transform_items_imgly_transform_instagram_portrait: 'Portrett',
    transform_items_imgly_transform_instagram_square: 'Kvadrat',
    transform_items_imgly_transform_twitter_profile: 'Profil',
    transform_items_imgly_transform_twitter_title: 'Tittel',
    transform_items_imgly_transform_twitter_post: 'Send',
    transform_transformActions_buttonFlipHorizontal: 'Snu horisontalt',
    transform_transformActions_buttonFlipVertical: 'Snu vertikalt',
    transform_transformActions_buttonRotateClockwise: 'Roter med klokken',
    transform_transformActions_buttonRotateAntiClockwise: 'Roter mot klokken',
}
