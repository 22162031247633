import {
    PaymentInfoFetched,
    PlanCancelFailed,
    PlanCanceled,
    PlanChangeFailed,
    PlanChangeStarted,
    PlanChangeSucceeded,
    PlanReactivated,
    PlanReactivationFailed,
    PurchaseFailed,
    PurchaseStarted,
    PurchaseSucceeded,
    StorageProductsFetched,
    FetchStripeSubscriptionInfoFailed,
    FetchStripeSubscriptionInfoSucceeded,
    UserStoragePlanFetched,
    PlanChangeActionRequired,
} from '~/state/storagePlan/actions'
import type { Dispatch } from '~/state/common/actions'
import { stripeMode } from '~/config/constants'
import { getServiceProvider } from './HostProvider'
import { getStripeService } from './services/StripeService'
import { ResponseNotOKError } from './toolbox'
import { fetchAccountInfo } from './currentUser'

export const fetchCreditCardInfo = async (dispatch: Dispatch) => {
    try {
        const paymentInfoResp =
            await getStripeService().getStripePaymentMethodInfo()
        dispatch(PaymentInfoFetched(paymentInfoResp))
    } catch (error) {
        if (error instanceof ResponseNotOKError) {
            // We get 404 Not Found when a user does not have any credit card
            if (error.response.status === 404) {
                dispatch(PaymentInfoFetched(null))
            }
        }
    }
}

const refreshUserSubscriptionInfo = (dispatch: Dispatch) => {
    return Promise.all([
        fetchCreditCardInfo(dispatch),
        fetchAccountInfo(dispatch),
        getUserStoragePlan(dispatch),
        getUserStripeSubscriptions(dispatch),
    ])
}

const getStorageProducts = async (dispatch: Dispatch) => {
    try {
        const resp = await getStripeService().getStripeProducts(stripeMode)
        dispatch(StorageProductsFetched(resp))
    } catch (_e) {
        /* No harm done */
    }
}

export const getUserStoragePlan = async (dispatch: Dispatch) => {
    try {
        const service =
            getServiceProvider().getAppServiceForLoggedInUserDefaults()
        const grants = await service.getUserGrants()
        dispatch(UserStoragePlanFetched(grants))
    } catch (_e) {
        /* No harm done */
    }
}

export const fetchStoragePlanInfo = (dispatch: Dispatch) => {
    return Promise.all([
        getStorageProducts(dispatch),
        getUserStoragePlan(dispatch),
        getUserStripeSubscriptions(dispatch),
    ])
}

export const cancelStoragePlan = async (dispatch: Dispatch, url: string) => {
    try {
        const service =
            getServiceProvider().getAppServiceForLoggedInUserDefaults()
        await service.executeGrantLink(url)
        dispatch(PlanCanceled())

        await refreshUserSubscriptionInfo(dispatch)
    } catch (_e) {
        dispatch(PlanCancelFailed())
    }
}

export const reactivateStoragePlan = async (
    dispatch: Dispatch,
    url: string,
) => {
    try {
        const service =
            getServiceProvider().getAppServiceForLoggedInUserDefaults()
        await service.executeGrantLink(url)
        dispatch(PlanReactivated())

        await refreshUserSubscriptionInfo(dispatch)
    } catch (_e) {
        dispatch(PlanReactivationFailed())
    }
}

export const subscribeToStoragePlan = async (
    dispatch: Dispatch,
    plan: string,
) => {
    try {
        dispatch(PurchaseStarted())
        await getStripeService().purchaseStripePlan(plan)
        dispatch(PurchaseSucceeded())

        await refreshUserSubscriptionInfo(dispatch)
    } catch (_e) {
        dispatch(PurchaseFailed())
    }
}

export const getUserStripeSubscriptions = async (dispatch: Dispatch) => {
    try {
        const data = await getStripeService().getAllSubscriptions()
        dispatch(FetchStripeSubscriptionInfoSucceeded(data))
    } catch (_e) {
        dispatch(FetchStripeSubscriptionInfoFailed())
    }
}

export const changeStripePlan = async (
    dispatch: Dispatch,
    plan: string,
): Promise<boolean> => {
    try {
        dispatch(PlanChangeStarted())

        const result = await getStripeService().updateStripePlan(plan)
        if ('status' in result) {
            // handle any action required stripe subscription status to create StepupIntent
            dispatch(PlanChangeActionRequired(result.subscription_id))
            return false
        }

        dispatch(PlanChangeSucceeded({ newPlanID: plan }))

        await refreshUserSubscriptionInfo(dispatch)
        return true
    } catch (_e) {
        dispatch(PlanChangeFailed())
        return false
    }
}
