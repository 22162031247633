import type {
    ActiveStripeProduct,
    StripePaymentInfo,
    StripeProduct,
    UpcomingStripeProduct,
    UserGrant,
} from '~/@types/backend-types'
import type { Action } from '../common/actions'
import { isType } from '../common/actions'
import {
    PaymentInfoFetched,
    PlanChangeFailed,
    PlanChangeStarted,
    PlanChangeSucceeded,
    PurchaseFailed,
    PurchaseStarted,
    PurchaseSucceeded,
    StorageProductsFetched,
    FetchStripeSubscriptionInfoSucceeded,
    UserStoragePlanFetched,
    FetchStripeSubscriptionInfoFailed,
    PlanChangeActionRequired,
    PlanChangeActionRequiredHandled,
} from './actions'

export type StripeSubscriptionInfo = {
    subscriptionId: string
    activeProduct?: ActiveStripeProduct
    upcomingProduct?: UpcomingStripeProduct
}

export type StorageState = {
    availableProducts: StripeProduct[]
    availableTrial: number
    userGrants: UserGrant[]
    paymentInfo: StripePaymentInfo | null
    stripeSubscriptionInfo: StripeSubscriptionInfo | null
    isUpdatingStoragePlan: boolean
    actionRequiredSubscriptionId: string | null
}

const initialState = {
    availableProducts: [],
    availableTrial: 0,
    userGrants: [],
    paymentInfo: null,
    stripeSubscriptionInfo: null,
    isUpdatingStoragePlan: false,
    actionRequiredSubscriptionId: null,
}

export const storagePlanReducer = (
    state: StorageState = initialState,
    action: Action,
): StorageState => {
    if (isType(action, StorageProductsFetched)) {
        return {
            ...state,
            availableProducts: action.payload.result,
            availableTrial: action.payload.trial_period_months,
        }
    }

    if (isType(action, UserStoragePlanFetched)) {
        return {
            ...state,
            userGrants: action.payload,
        }
    }
    if (isType(action, PaymentInfoFetched)) {
        return {
            ...state,
            paymentInfo: action.payload,
        }
    }

    if (isType(action, PurchaseStarted) || isType(action, PlanChangeStarted)) {
        return {
            ...state,
            isUpdatingStoragePlan: true,
            actionRequiredSubscriptionId: null,
        }
    }
    if (
        isType(action, PurchaseSucceeded) ||
        isType(action, PurchaseFailed) ||
        isType(action, PlanChangeSucceeded) ||
        isType(action, PlanChangeFailed)
    ) {
        return {
            ...state,
            isUpdatingStoragePlan: false,
            actionRequiredSubscriptionId: null,
        }
    }

    if (PlanChangeActionRequired.match(action)) {
        return {
            ...state,
            isUpdatingStoragePlan: false,
            actionRequiredSubscriptionId: action.payload,
        }
    }

    if (PlanChangeActionRequiredHandled.match(action)) {
        return {
            ...state,
            actionRequiredSubscriptionId: null,
        }
    }

    if (FetchStripeSubscriptionInfoSucceeded.match(action)) {
        const { subscription_id, products } = action.payload
        const activeProduct = products.find(
            (s): s is ActiveStripeProduct => s.start_at === undefined,
        )
        const upcomingProduct = products.find(
            (s): s is UpcomingStripeProduct => s.start_at !== undefined,
        )
        return {
            ...state,
            stripeSubscriptionInfo: subscription_id
                ? {
                      subscriptionId: subscription_id,
                      activeProduct,
                      upcomingProduct,
                  }
                : null,
        }
    }

    if (FetchStripeSubscriptionInfoFailed.match(action)) {
        return {
            ...state,
            stripeSubscriptionInfo: null,
        }
    }

    return state
}

export const storagePlanReducerMapObj = {
    storagePlan: storagePlanReducer,
}

export type StateWithStoragePlan = StateOfReducerMapObj<
    typeof storagePlanReducerMapObj
>
